<!-- 客观题 -->
<template>
	<div class="objectiveWork-page">
		<div class="nav-content">
			<div class="nav-title flex-ac">
				<div class="back flex-jc-ac" @click="back">&lt; | 返回</div>
				<div class="chapte-title">{{ title }}</div>
			</div>
		</div>
		<div class="work-container">
			<div class="work-content flex-jb">
				<div class="left">
					<div class="progress">{{ activeWorkPage }} / {{ workInfo.tmcount }}</div>
					<div class="problems" v-if="workInfo && workInfo.info && workInfo.info.length > 0">
						
						<div class="content">
							{{ workInfo.info[0].tmcontent }}
						</div>
					</div>
				</div>
				<div class="right">
					<div class="title">请选择({{ workInfo.info[0].tmoptions | workType }})</div>
					<!-- <div class="title">{{ workInfo.info[0].tmoptions | workType }}</div> -->
					<div class="options">
						<div class="item flex-ac" v-for="(item, index) in workInfo.tmoptionlist" :key="index" @click="handleCheck(item.id)" :class="{ checked: hasChecked(item.id) || item.uxxtype == 2 }">
							{{ item.dt_options }}
						</div>
					</div>

					<div class="answer" v-if="workInfo.isuserdt == 1 && workInfo.zqdanlist && workInfo.zqdanlist.length > 0">
						<span v-if="workInfo.isuserdatruetype == 1">
							正确答案为：
							<span class="err">{{ showWorkList(workInfo.zqdanlist) }} </span>
						</span>
						<span class="success" v-else>回答正确！</span>
					</div>

					<div class="btn-wrap flex-jb">
						<div class="btn"><div class="prev" @click="getPrevWork" v-if="activeWorkPage != 1">上一题</div></div>
						<div class="btn"><div class="next" @click="getNextWork" v-if="workInfo.tmcount != activeWorkPage && workInfo.tmcount != 1&&workInfo.isuserdt != 2">下一题</div></div>
						<div class="submit" v-if="workInfo.isuserdt == 2" @click="submitWork">提交</div>
					</div>
					<div class="back" @click="back">返回</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { $getObjectiveWork, $submitObjectiveWork } from "../services/course";
export default {
	name: "ObjectiveWork",
	data() {
		return {
			courseId: "",
			title: "",
			type: "1",
			workInfo: {},
			activeWorkPage: 0,
			checkedIndex: [], // 单选题，默认-1 即未选择 多选题为数组下标
			loading: false,
			workSubmiting: false,
		};
	},
	components: {},

	filters: {
		workType(type) {
			if (type == 0) {
				return "单选题";
			} else if (type == 1) {
				return "多选题";
			} else if (type == 2) {
				return "调查问卷-单选";
			} else if (type == 3) {
				return "调查问卷-多选";
			} else {
				return "错误类型";
			}
		},
	},

	computed: {},

	created() {
		this.title = this.$route.query.title;
		this.courseId = this.$route.query.id;
		this.type = this.$route.query.type;

		// this.courseId = '7500909933887488'
		this.getWorkList(1);
	},

	methods: {
		async getWorkList(page) {
			if (this.loading) return;
			this.loading = true;
			try {
				const { data } = await $getObjectiveWork({
					ksid: this.courseId,
					page,
					type: this.type,
				});
				this.loading = false;
				this.activeWorkPage = page;
				this.workInfo = data;
				if (this.workInfo.info[0].tmoptions == 0 || this.workInfo.info[0].tmoptions == 2) {
					this.checkedIndex = -1;
				} else {
					this.checkedIndex = [];
				}
			} catch (error) {
				this.loading = false;
			}
		},
		handleCheck(index) {
			if (this.workInfo.isuserdt == 1) return;
			if (Array.isArray(this.checkedIndex)) {
				let i = this.checkedIndex.indexOf(index);
				if (i === -1) {
					this.checkedIndex.push(index);
				} else {
					this.checkedIndex.splice(i, 1);
				}
			} else {
				this.checkedIndex = index;
			}
		},
		hasChecked(value) {
			if (Array.isArray(this.checkedIndex)) {
				// 多选题
				return this.checkedIndex.includes(value);
			} else {
				// 单选题
				return this.checkedIndex === value;
			}
		},
		hasSubmit(id) {
			if (this.workInfo.isuserdt == 2 || this.workInfo.zqdanlist.length == 0) return false;
			return this.workInfo.zqdanlist.some(item => item.id == id);
		},
		showWorkList(info) {
			console.log(info)
			if (info.length == 0) return "";
			let str = "";
			info.forEach(item => {
				str = str + item.dt_options + " ";
			});
			return str;
		},
		getPrevWork() {
			if (this.loading) return;
			if (Array.isArray(this.checkedIndex)) {
				this.checkedIndex = [];
			} else {
				this.checkedIndex = -1;
			}
			const page = this.activeWorkPage - 1;
			this.getWorkList(page);
		},
		getNextWork() {
			if (this.loading) return;
			if (Array.isArray(this.checkedIndex)) {
				this.checkedIndex = [];
			} else {
				this.checkedIndex = -1;
			}
			const page = this.activeWorkPage + 1;
			this.getWorkList(page);
		},
		async submitWork() {
			if (this.workSubmiting) return;
			if (this.checkedIndex == -1 || this.checkedIndex.length == 0) {
				this.$message.error("请先选择您认为正确的选项");
				return;
			}
			let tmtype = Array.isArray(this.checkedIndex) ? 1 : 0;
			let options = {};

			let req = new FormData();
			req.append("tmtype", tmtype);
			const sid = this.$store.getters.useInfo.id;
			req.append("sid", sid);
			req.append("tmid", this.workInfo.info[0].id);

			if (Array.isArray(this.checkedIndex)) {
				// 多选题
				this.checkedIndex.forEach(item => {
					req.append(`tmopition[${item}]`, item);
					// req[`wdaddlist[${item.id}]`] = item.average;
				});
			} else {
				// 单选题
				req.append(`tmopition[${this.checkedIndex}]`, this.checkedIndex);
				// options[this.checkedIndex] = this.checkedIndex;
			}
			this.workSubmiting = true;
			try {
				const { data } = await $submitObjectiveWork(req);
				this.$set(this.workInfo, "zqdanlist", data);
				this.$set(this.workInfo, "isuserdt", 1);
				this.workSubmiting = false;
				this.$message.success("提交成功");
			} catch (error) {
				this.workSubmiting = false;
			}
		},
		back() {
			this.$router.back();
		},
	},
};
</script>

<style scoped lang="less">
.nav-content {
	height: 52px;
	background: #f3f5f6;
	.nav-title {
		width: 1200px;
		height: 52px;
		margin: 0 auto;
		font-size: 16px;
		color: #1c1f21;
		.back {
			width: 120px;
			height: 37px;
			border-radius: 20px;
			color: #fff;
			margin-right: 20px;
			background-color: rgba(153, 153, 153, 1);
			cursor: pointer;
		}
	}
}
.work-container {
	background: #4d555d;
	min-height: 986px;
	padding: 50px 0;
	.work-content {
		width: 1200px;
		margin: 0 auto;
		.left {
			width: 600px;
			padding-top: 22px;
			.progress {
				width: 169px;
				height: 52px;
				background: rgba(140, 144, 148, 0.5);
				border-radius: 26px;
				font-size: 32px;
				line-height: 52px;
				text-align: center;
				color: #ffffff;
			}
			.problems {
				margin-top: 60px;
				padding-left: 40px;
				padding-right: 60px;
				.title {
					font-size: 36px;
					color: #f3f5f6;
					margin-bottom: 30px;
				}
				.content {
					font-size: 26px;
					color: #ffffff;
					line-height: 36px;
				}
			}
		}
		.right {
			width: 600px;
			min-height: 800px;
			background: #222628;
			border-radius: 10px;
			padding: 60px 48px;
			.title {
				font-size: 28px;
				line-height: 1;
				color: #f3f5f6;
			}
			.options {
				margin-top: 85px;

				.item {
					width: 100%;
					min-height: 60px;
					line-height: 30px;
					cursor: pointer;
					margin-bottom: 40px;
					padding: 15px 30px;
					border: 1px solid #ffffff;
					border-radius: 20px;
					font-size: 22px;
					color: #f3f5f6;
					&:hover {
						border: 1px solid #15abee;
						color: #15abee;
					}
					&.checked {
						background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
						border: 1px solid transparent;
					}
					&.checked:hover {
						color: #fff;
					}
				}
			}
			.answer {
				margin-bottom: 30px;
				opacity: 1;
				width: 100%;
				height: 68px;
				line-height: 68px;
				text-align: center;
				background-color: rgba(84, 92, 99, 0.5);
				border-radius: 34px;
				font-size: 20px;
				color: rgba(250, 118, 25, 1);
				.err {
					color: rgba(250, 118, 25, 1);
					font-weight: 500;
					font-size: 24px;
				}
				.success {
					font-weight: 500;
					font-size: 24px;
					color: #56fdd3;
				}
			}
			.submit {
				width: 150px;
				height: 54px;
				line-height: 54px;
				background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
				border-radius: 27px;
				cursor: pointer;
				user-select: none;
				font-size: 18px;
				text-align: center;
				color: #fff;
			}
			.btn-wrap {
				.btn {
					width: 150px;
					height: 54px;
				}
				.next,
				.prev {
					width: 150px;
					height: 54px;
					line-height: 54px;
					background: #545c63;
					border-radius: 27px;
					cursor: pointer;
					user-select: none;
					font-size: 18px;
					text-align: center;
					color: #fff;
				}
			}
			.back {
				width: 300px;
				margin: 30px auto 0;
				height: 54px;
				line-height: 54px;
				background: linear-gradient(90deg, #15abee 0%, #5dccff 100%);
				border-radius: 27px;
				cursor: pointer;
				user-select: none;
				font-size: 18px;
				text-align: center;
				color: #fff;
			}
		}
	}
}
</style>
